import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ConverstaionImg from "../../static/assets/1- speech therapy.png"
import ChildPsyImg from "../../static/assets/1- counselling.png"
import ActivityTrackerImg from "../../static/assets/1- NSP.png"

const ZebraDiv = styled("div")`
  position: relative;
  background-color: var(--primary-zebra-color);
  width: 99vw;
  left: calc(-50vw + 50%);
`

const ZebraDivEx = styled("div")`
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
`

const STherapyImgCss = css`
  height: 220px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

/*
const NpSection = () => {
  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }
  return (
    <div className="content-master">
      <Container>
        <Row>
          <Col xs={12}>
            <h3>
              <div
                css={css`
                  text-align: center;
                  font-weight: bold;
                `}
              >
                <a className="anchor" id="np" />
                {tt("pages.therapy.np.title")}
              </div>
            </h3>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col>
            <div className="text-dark">
              {tt("pages.therapy.np.desc1_1")}
              <a href="https://todo.com">
                {tt("pages.therapy.np.desc1_link1")}
              </a>
              {
              <a href="#感覺統合">{tt("pages.therapy.np.desc1_link2")}</a>
              }
              {tt("pages.therapy.np.desc1_2")}
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col className="pb-3">
            <div className="d-flex flex-column">
              <h5 className="font-weight-bold">
                <a className="anchor" id="感覺統合" />
                {tt("pages.therapy.np.title2")}
              </h5>
              <div className="text-dark">{tt("pages.therapy.np.desc2")}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const PsySection = () => {
  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }
  return (
    <div className="content-master">
      <Container>
        <Row>
          <Col xs={12}>
            <h3>
              <div
                css={css`
                  text-align: center;
                  font-weight: bold;
                `}
              >
                <a className="anchor" id="psy" />
                {tt("pages.therapy.p&p.title")}
              </div>
            </h3>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col>
            <div className="text-dark">
              {tt("pages.therapy.p&p.desc1_1")}
              <a href="#體感治療">{tt("pages.therapy.p&p.desc1_link1")}</a>
              {tt("pages.therapy.p&p.desc1_2")}
              <a href="#心理分析">{tt("pages.therapy.p&p.desc1_link2")}</a>
              {tt("pages.therapy.p&p.desc1_3")}
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col className="pb-3">
            <div className="d-flex flex-column">
              <h5 className="font-weight-bold">
                <a className="anchor" id="體感治療" />
                {tt("pages.therapy.p&p.title4")}
              </h5>
              <div className="text-dark">{tt("pages.therapy.p&p.desc4")}</div>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col className="pb-3">
            <div className="d-flex flex-column">
              <h5 className="font-weight-bold">
                <a className="anchor" id="心理分析" />
                {tt("pages.therapy.p&p.title3")}
              </h5>
              <div className="text-dark">{tt("pages.therapy.p&p.desc3_1")}</div>
              <div className="text-dark pt-3">
                {tt("pages.therapy.p&p.desc3_2")}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const SpeechSection = () => {
  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  const Part1Ex = ({ mykey, descLink, showCause, causeLink, titleLink }) => {
    const title = mykey + ".title"
    const desc = mykey + ".desc"
    const descLinkText = mykey + ".descLink"
    const descEx = mykey + ".descEx"
    const cause = mykey + ".cause"
    const causeLinkText = mykey + ".causeLink"
    const causeEx = mykey + ".causeEx"

    const genCauseLink = () => {
      if (causeLink) {
        return (
          <>
            <Link to={causeLink}>{tt(causeLinkText)}</Link>
            {tt(causeEx)}
          </>
        )
      }
    }

    const genLink = () => {
      if (descLink) {
        return (
          <>
            <Link to={descLink}>{tt(descLinkText)}</Link>
            {tt(descEx)}
          </>
        )
      }
    }

    const genCause = () => {
      if (showCause !== false) {
        return (
          <>
            <div className="pl-3">{tt("pages.speech.cause")}</div>
            <ul>
              <li>
                {tt(cause)}
                {genCauseLink()}
              </li>
            </ul>
          </>
        )
      } else return <></>
    }

    const genTitle = () => {
      if (titleLink) {
        return (
          <div className="font-weight-bold">
            <Link to={titleLink}>{tt(title)}</Link>
          </div>
        )
      } else {
        return <div className="font-weight-bold">{tt(title)}</div>
      }
    }

    return (
      <div className="d-flex flex-column pt-2">
        {genTitle()}
        <ul>
          <li>
            {tt(desc)}
            {genLink()}
          </li>
        </ul>
        {genCause()}
      </div>
    )
  }

  return (
    <div className="content-master">
      <Container>
        <Row>
          <Col xs={12}>
            <h3>
              <div
                css={css`
                  text-align: center;
                  font-weight: bold;
                `}
              >
                <a className="anchor" id="speech" />
                {tt("pages.speech.title1")}
              </div>
            </h3>
          </Col>
        </Row>
        {
        <Row className="pt-4">
          <Col>
            <div className="text-dark">{tt("pages.speech.desc1")}</div>
          </Col>
        </Row>
        }
        <Row>
          <Col className="pt-4">
            <div className="d-flex flex-column">
              <h5 className="font-weight-bold">
                {tt("pages.speech.part1.title")}
              </h5>
              <div css={css``} className="text-dark">
                <Part1Ex
                  mykey="pages.speech.part1.point1"
                  descLink="/#Syndromes"
                />
                <Part1Ex
                  mykey="pages.speech.part1.point2"
                  descLink="/#Syndromes"
                />
                <Part1Ex mykey="pages.speech.part1.point3" />
                <Part1Ex
                  mykey="pages.speech.part1.point4"
                  causeLink="/puberphonia"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row id="adult">
          <Col className="pt-4">
            <div className="d-flex flex-column">
              <h5 className="font-weight-bold">
                {tt("pages.speech.part2.title")}
              </h5>
              <div css={css``} className="text-dark">
                <Part1Ex
                  showCause={false}
                  mykey="pages.speech.part2.point1"
                  titleLink="/aphasia"
                />
                <Part1Ex
                  showCause={false}
                  mykey="pages.speech.part2.point2"
                  titleLink="/aphasia"
                />
                <Part1Ex
                  showCause={false}
                  mykey="pages.speech.part2.point3"
                  titleLink="/aphasia"
                />
                <Part1Ex showCause={false} mykey="pages.speech.part2.point4" />
                <Part1Ex
                  showCause={false}
                  mykey="pages.speech.part2.point5"
                  titleLink="/swallowingdifficulty"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}*/

const STherapy = ({ t }) => {
  return (
    <Container>
      <Row>
        <Col md={4}>
          <Link to="/np">
            <div className="pb-4 d-flex flex-column">
              <img css={STherapyImgCss} src={ActivityTrackerImg} alt="" />
              <div className="font-weight-bold text-center">{t("np")}</div>
            </div>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/counselling">
            <div className="pb-4 d-flex flex-column">
              <img css={STherapyImgCss} src={ChildPsyImg} alt="" />
              <div className="font-weight-bold text-center">
                {t("p&p")}
              </div>
            </div>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/speechtherapy">
            <div className="pb-4 d-flex flex-column">
              <img css={STherapyImgCss} src={ConverstaionImg} alt="" />
              <div className="font-weight-bold text-center">
                {t("SpeechPathology")}
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

const ThePage = () => {
  /*
  const data = useStaticQuery(graphql`
    query abtQuery {
      img1: file(relativePath: { eq: "sensory01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "sensory02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img3: file(relativePath: { eq: "sensory03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img4: file(relativePath: { eq: "sensory04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  */

  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.therapy" description="seo.desc.main" />
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.therapy.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="d-flex flex-column">
                <div className="text-dark">
                  {tt("pages.therapy.desc1_1")}
                  {/*
                  <Link to="/TODO">{tt("pages.therapy.desc1_link1")}</Link>
                  {tt("pages.therapy.desc1_2")}
                  <Link to="/TODO">{tt("pages.therapy.desc1_link2")}</Link>
                  {tt("pages.therapy.desc1_3")}
                  */}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <STherapy t={tt} />
            </Col>
          </Row>
	  {/*<ZebraDiv>
            <Row className="pl-5">
              <Col>
                <NpSection />
              </Col>
            </Row>
          </ZebraDiv>
          <Row>
            <Col>
              <PsySection />
            </Col>
          </Row>
          <ZebraDiv>
            <Row className="pl-5">
              <Col>
                <SpeechSection />
              </Col>
            </Row>
          </ZebraDiv>*/}
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
